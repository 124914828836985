import { Loaders } from '@angloeastern/react-library';
import ConfirmRemove from './ConfirmRemove';
import Controls from './Controls';
import Dropdown from './Dropdown';
import SC from './style';
import useViewModel from './useViewModel';

const LoadFavorite: React.FC = () => {
  const {
    ready,
    label,
    selected,
    favorites,
    showControls,
    isLoading,
    onSelect,
    onRemove
  } = useViewModel();

  return isLoading ? (
    <Loaders.Ellipsis size={52} />
  ) : (
    ready && (
      <>
        <SC.Label>{label}</SC.Label>
        <Dropdown
          key={selected}
          selected={selected}
          onClick={onSelect}
          options={favorites}
        />
        <Controls show={showControls} onRemove={onRemove} />
        <ConfirmRemove />
      </>
    )
  );
};

export default LoadFavorite;
