import { useImageResizer } from '../../../ImageResizer/context';
import SC from './style';
import useViewModel from './useViewModel';

const ImageUploader: React.FC = () => {
  const { show, setShow } = useImageResizer();
  const { image, label, inputFileRef, onBrowse, onFileSelected } =
    useViewModel();

  return !show ? (
    <SC.ImageContainer>
      <SC.Image
        onClick={() => setShow(true)}
        src={image}
        alt="profile-avatar"
      />
      <SC.ImageHeading onClick={onBrowse}>{label}</SC.ImageHeading>
      <SC.HiddenInput
        aria-label="input"
        id="logo-input"
        ref={inputFileRef}
        type="file"
        accept="image/*"
        onChange={onFileSelected}
      />
    </SC.ImageContainer>
  ) : null;
};

export default ImageUploader;
