import { errorTracker } from '@utils/errorTracker';
import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';
import * as Api from './api';
import type { ContextType, NotificationSettingCategory } from './types';
import { labelMappings, type ISetting, type ISettings } from './types';

const Context = createContext({} as ContextType);

export const Provider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISettings | null>(null);

  const fetchNotificationSettings = async () => {
    setLoading(true);
    try {
      const response = await Api.fetchNotificaitonSettings();
      const settings = response.map((setting: ISetting) => {
        const item = labelMappings.find(
          (item: ISetting) => item.eventType === setting.eventType
        );
        return {
          ...setting,
          label: item?.label
        };
      });

      setData(settings);
    } catch (error) {
      errorTracker(error);
    }
    setLoading(false);
  };

  const updateNotificationSettings = async (settings: ISettings) => {
    setLoading(true);
    try {
      await Api.updateNotificationSettings(settings || []);
    } catch (error) {
      errorTracker(error);
    }
    setLoading(false);
  };

  const onSettingChange = (
    category: NotificationSettingCategory,
    value: boolean,
    eventType: string
  ) => {
    const updatedData = [...(data || [])];
    const index = updatedData.findIndex((item) => item.eventType === eventType);

    if (index >= 0) {
      updatedData[index][category] = value;
      setData(updatedData);
      updateNotificationSettings(updatedData);
    }
  };

  return (
    <Context.Provider
      value={{
        data,
        fetchNotificationSettings,
        updateNotificationSettings,
        onSettingChange,
        loading
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSystemNotificationsSettings = () => useContext(Context);

export default Provider;
