import { apiGet, apiPut } from '@api/callAPI';
import type { ISettings } from './types';

export const fetchNotificaitonSettings = (signal?: AbortSignal) =>
  apiGet(`/notifications/subscription`, false, { signal });

export const updateNotificationSettings = (
  settings: ISettings,
  signal?: AbortSignal
) =>
  apiPut(`/notifications/subscription`, settings, false, {
    signal
  });
