import { DropdownStyles } from '@angloeastern/react-library';
import styled from 'styled-components';

const { Header } = DropdownStyles;

const HeadContainer = styled(Header).attrs({ 'aria-label': 'item-value' })`
  display: flex;
  color: ${({ theme }) => theme.colors.licorice};
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  background: transparent;
  padding: 3px 14px !important;
  border-radius: 8px;
  box-shadow: none;

  .circle {
    display: none;
  }

  &:after {
    font-size: 24px;
  }

  &+div li {
    padding 12px 16px !important;
  }

  @media only screen and (min-width: 768px) {
    align-self: center;
    align-items: center;
    
    &:after {
      line-height: 56px;
      right: 24px;
      font-size: 14.4px;
    }  
  }
`;

const LabelContainer = styled.div`
  flex: 1 0;
  background: transparent;
  align-self: center;
  font-size: 14px;
`;

const SC = {
  HeadContainer,
  LabelContainer
};

export default SC;
