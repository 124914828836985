import { useAuthUser } from '@context/AuthUser';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator(TranslationNS.COMMON, 'myProfile');
  const {
    profile: { firstName, lastName }
  } = useAuthUser();

  return {
    firstName: {
      label: t('firstName'),
      value: firstName || ''
    },
    lastName: {
      label: t('lastName'),
      value: lastName || ''
    }
  };
};

export default useViewModel;
