import AlertIcon from './AlertIcon';
import { Provider as NotificationProvider } from './context';
import Display from './Display';
import { Provider as SettingsProvider } from './Display/SettingList/context';

const SystemNotifications: React.FC = () => (
  <NotificationProvider>
    <SettingsProvider>
      <AlertIcon />
      <Display />
    </SettingsProvider>
  </NotificationProvider>
);

export default SystemNotifications;
