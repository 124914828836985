import BluishRipples from '@components/Loading/BluishRipples';
import SC from './style';

const Loader: React.FC = () => {
  return (
    <SC.Loader>
      <BluishRipples />
    </SC.Loader>
  );
};

export default Loader;
