import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { TranslationNS } from '@hooks/useTranslator';
import { getDefaultLanguage } from '@utils/index';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'path', 'navigator'],
      caches: ['cookie']
    },
    fallbackLng: getDefaultLanguage(),
    react: {
      useSuspense: true
    },
    debug: false,
    ns: [
      TranslationNS.COMMON,
      TranslationNS.CERTIFICATES,
      TranslationNS.CREW,
      TranslationNS.ERRORPAGE,
      TranslationNS.FINANCIAL,
      TranslationNS.KPIS,
      TranslationNS.LOGIN,
      TranslationNS.NAVIGATION,
      TranslationNS.NOTIFICATIONS,
      TranslationNS.REPORTS
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
