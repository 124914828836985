import type { Size } from '@components/Kpi/Item/style';
import styled from 'styled-components';

const Container = styled.div<{ $size: Size }>`
  display: flex;
  gap: ${({ $size }) => ($size === 'default' ? 5 : 2)}px;
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return `
          gap: 2px;
          --arrow-size: 8px;
          --label-transform: uppercase;
          --label-whitespace: break-spaces;

          --text-size: 8px;
          --text-weight: normal;
          --text-line-height: 16px;
          --text-transform: uppercase;
          --text-whitespace: break-spaces;
        `;
      case 'large':
        return `
          gap: 5px;
          --arrow-size: 16px;
          --label-transform: none;
          --label-whitespace: nowrap;

          --text-size: 10px;
          --text-weight: normal;
          --text-line-height: 16px;
          --text-transform: uppercase;
          --text-whitespace: nowrap;
        `;
      default:
        return `
          gap: 5px;
          --arrow-size: 16px;
          --label-transform: none;
          --label-whitespace: nowrap;

          --text-size: 13px;
          --text-weight: normal;
          --text-line-height: 20px;
          --text-transform: none;
          --text-whitespace: nowrap;
        `;
    }
  }}

  ${({ $size }) =>
    $size === 'default'
      ? `
    gap: 5px;
    --arrow-size: 16px;
    --label-transform: none;
    --label-whitespace: nowrap;
  `
      : `
    gap: 2px;
    --arrow-size: 8px;
    --label-transform: uppercase;
    --label-whitespace: break-spaces;
  `};
`;

const Label = styled.div`
  white-space: var(--label-whitespace);
  align-self: center;
  text-transform: var(--label-transform);
`;

const Text = styled.span`
  font-weight: var(--text-weight);
  line-height: var(--text-line-height);
  font-size: var(--text-size);
  text-transform: var(--text-transform);
  white-space: var(--text-whitespace);
`;

const BlackText = styled(Text)`
  color: black;
  font-weight: bold;
`;

const YellowText = styled(Text)`
  color: ${({ theme }) => theme.colors.buttercup};
`;

const GreenText = styled(Text)`
  color: ${({ theme }) => theme.colors.robinEggBlue};
`;

const RedBody = styled.span`
  ${({ theme }) => theme.text.redNegativeValue}
`;

const SC = {
  Container,
  Label,
  GreenText,
  YellowText,
  BlackText,
  Text,
  RedBody
};

export default SC;
