import styled from 'styled-components';

const Container = styled.div<{
  $color: string;
  $size: number;
}>`
  ${({ $size }) => `
    width: ${$size}px;
    height: ${$size}px;
    padding: ${$size * 0.0714}px;
  `}
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;

  ${({ $color, theme }) => {
    const bgColor =
      $color === 'light' ? '#f2f2f2' : theme.colors.darkMidnightBlue;
    const hoverBgColor =
      $color === 'light' ? theme.colors.darkMidnightBlue : '#f2f2f2';

    return `
      background-color: ${bgColor};
      
      &:hover {
        background-color: ${hoverBgColor};

        > div {
          background-image: url('/images/jibe-${$color}.svg');
        }
      }
    `;
  }}
`;

const Icon = styled.div.attrs({ 'aria-label': 'icon-wrapper' })<{
  $color: string;
}>`
  width: 92%;
  height: 92%;
  ${({ $color }) => `
    background-image: url('/images/jibe-${
      $color === 'dark' ? 'light' : 'dark'
    }.svg');
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50%;
  align-self: center;
`;

const SC = {
  Container,
  Icon
};

export default SC;
