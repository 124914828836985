import { useDateUtils } from '@hooks/useDateUtils';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';

const useTimeStamp = (timestamp: string) => {
  const { t } = useTranslator(TranslationNS.COMMON, 'timeStamp');
  const { formatDate } = useDateUtils();
  const currentTime = new Date();
  const messageTime = new Date(timestamp);
  const diffInSeconds = (currentTime.getTime() - messageTime.getTime()) / 1000;
  const diffInDays = Math.floor(diffInSeconds / 86400);

  if (diffInSeconds <= 60) {
    return t('justNow');
  } else if (diffInSeconds <= 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return t('minutesAgo', { figure: minutes });
  } else if (diffInSeconds <= 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return t('hoursAgo', { figure: hours });
  } else if (diffInDays === 1) {
    return t('yesterday');
  } else if (diffInDays === 7) {
    return t('oneWeekAgo');
  } else if (diffInDays < 7) {
    const days = Math.floor(diffInDays);
    return t('daysAgo', { figure: days });
  } else {
    return formatDate(timestamp);
  }
};

export default useTimeStamp;
