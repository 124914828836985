import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Icon = styled(FontAwesomeIcon).attrs({
  'aria-label': 'reset-button'
})`
  color: ${({ theme }) => theme.colors.darkCharcoal};
  font-size: 16px;
  display: flex;
  align-self: center;
`;

const Button = styled.button`
  display: inline-flex;
  outline: none;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.lightSilver};
  border: none;
  line-height: 16px;
  font-size: 14px;
  width: 42px;
  height: 42px;
  justify-content: center;
  box-shadow: 0 5px 10px 2px ${({ theme }) => theme.colors.black10};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.weldonBlue};

    > svg {
      color: white;
    }
  }
`;

const SC = {
  Icon,
  Button
};

export default SC;
