export interface VesselMarker {
  vesselAeCode: string;
  vesselName: string;
  latitude?: number | null;
  longitude?: number | null;
  timestamp?: string | null;
}

export type FleetMarkers = Array<VesselMarker>;

export enum Actions {
  FETCH_MARKERS = 'fetch-markers',
  UNSET_MARKERS = 'unset-markers',
  SET_ERROR = 'set-error',
  UPDATE_MARKERS = 'update-markers'
}

export interface State {
  markers: FleetMarkers | null;
  error: boolean;
}

export type Action =
  | { type: Actions.FETCH_MARKERS; payload: FleetMarkers }
  | { type: Actions.UPDATE_MARKERS; payload: FleetMarkers }
  | { type: Actions.UNSET_MARKERS }
  | { type: Actions.SET_ERROR };
