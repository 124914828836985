import { useRef } from 'react';

import { useConfig } from '@context/Config';
import { useProfile } from '@context/Profile';
import useImgUtils from '@hooks/useImgUtils';
import { useTranslator } from '@hooks/useTranslator';
import { errorTracker } from '@utils/errorTracker';

const useViewModel = () => {
  const { t } = useTranslator();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { addPrefix, removePrefix, toBase64 } = useImgUtils();
  const { DUMMY_SAILOR } = useConfig();
  const {
    state: {
      user: { profile: profileData }
    },
    edit
  } = useProfile();

  const onImageChange = (data: string) => {
    edit({
      ...profileData,
      imageData: data
    });
  };

  const onBrowse = () => {
    if (inputFileRef) {
      const ref = inputFileRef.current as unknown as HTMLInputElement;
      ref.click();
    }
  };

  const onFileSelected = async (e: any) => {
    const file = e.target.files[0];
    if (file.size > 500 * 1000) {
      console.warn('File size exceeded 500 kb'); // translation will be done once size confirmed
      return;
    }
    if (file) {
      try {
        const base64 = await toBase64(file);
        onImageChange(removePrefix(base64));
      } catch (error) {
        errorTracker(error);
      }
    }
  };

  return {
    image: addPrefix(profileData?.imageData) || DUMMY_SAILOR,
    label: t('myProfile.uploadPhoto'),
    inputFileRef,
    onBrowse,
    onFileSelected
  };
};

export default useViewModel;
