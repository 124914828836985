import {
  AlertType,
  useAlertMessages,
  useConfirmDialog
} from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';
import { useEffect } from 'react';

const useViewModel = () => {
  const { t } = useTranslator();
  const { setConfirm } = useConfirmDialog();
  const {
    state: { selected },
    unselect,
    clearActiveItem,
    setLoading,
    removeFavorite
  } = useFavorite();
  const { clearFavorite } = useVessels();
  const { addAlert } = useAlertMessages();

  const controller = new AbortController();
  const signal = controller.signal;

  const onCancel = () => setConfirm(false);

  const confirmRemove = async () => {
    if (selected) {
      try {
        setLoading(true);
        await removeFavorite(selected, signal);
        addAlert({
          type: AlertType.SUCCESS,
          body: t('favorites.success.delete')
        });
        setLoading(false);
        unselect();
        clearFavorite();
        clearActiveItem();
      } catch (error) {
        addAlert({
          type: AlertType.ERROR,
          body: t('favorites.errors.delete')
        });
        setLoading(false);
      }
    }
  };

  const onContinue = async () => {
    await confirmRemove();
    setConfirm(false);
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return {
    title: t('favorites.removeConfirmation'),
    noLabel: t('favorites.cancel'),
    yesLabel: t('favorites.continue'),
    version: 'v2',
    onYes: onContinue,
    onNo: onCancel,
    children: t('favorites.deleteFilterAlert')
  };
};

export default useViewModel;
