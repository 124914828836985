import styled from 'styled-components';

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  @media only screen and (min-width: 768px) {
    width: 36px;
    height: 36px;

    &:hover {
      box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.tiffanyBlue80};
    }
  }
`;

const Initial = styled.div`
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.brightYellow};

  @media only screen and (min-width: 768px) {
    width: 36px;
    height: 36px;
    line-height: 34px;

    &:hover {
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
    }
  }
`;

const SC = {
  Avatar,
  Initial
};

export default SC;
