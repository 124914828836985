import { Button, SecondaryButton } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const ButtonsRow: React.FC = () => {
  const { labels, onCancel, onUpdate } = useViewModel();

  return (
    <SC.Container>
      <SecondaryButton version={'v2'} onClick={onCancel}>
        {labels.cancel}
      </SecondaryButton>
      <Button version={'v2'} onClick={onUpdate}>
        {labels.update}
      </Button>
    </SC.Container>
  );
};

export default ButtonsRow;
