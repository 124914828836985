import { Provider } from '@context/SearchableView';
import type { Props } from './Presenter';
import Presenter from './Presenter';

const SearchableView: React.FC<Props> = (props) => (
  <Provider>
    <Presenter {...props} />
  </Provider>
);

export default SearchableView;
