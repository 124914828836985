import { Input } from '@angloeastern/react-library';

import SC from '../../style';
import { useViewModel } from './useViewModel';

export const NewPassword: React.FC = () => {
  const { label, inputProps } = useViewModel();

  return (
    <SC.FormRow data-testid="new-password">
      <SC.FormControl>
        <SC.Label>{label}</SC.Label>
        <Input {...inputProps} />
      </SC.FormControl>
    </SC.FormRow>
  );
};

export default NewPassword;
