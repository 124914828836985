import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 5px;
  padding: 4px 8px;
  margin-right: 10px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  justify-content: end;
  color: ${({ theme }) => theme.colors.darkCyan};

  &:hover {
    background: ${({ theme }) => theme.colors.darkMidnightBlue};
    box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.black10};

    > svg {
      color: ${({ theme }) => theme.colors.lightCyan};
    }

    > span {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 2px;
    width: 100%;
    height: 14px;
    border-right: 1px solid ${({ theme }) => theme.colors.darkCyan};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  flex: 0 0;
  color: ${({ theme }) => theme.colors.tiffanyBlue};
  font-size: 10px;
  line-height: 10px;
`;

const Label = styled.span`
  font-size: 12px;
  text-wrap: nowrap;
`;

const SC = {
  Container,
  Icon,
  Label
};

export default SC;
