import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';
import { useEffect } from 'react';

import { AlertType, useAlertMessages } from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';
import type { Favorite } from '@context/Favorite/types';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    state: { favorites, dirty, isLoading, activeItem },
    updateFavorite,
    createFavorite,
    setDirty,
    setLoading,
    select
  } = useFavorite();
  const { setFavorite } = useVessels();
  const { addAlert } = useAlertMessages();

  const controller = new AbortController();
  const signal = controller.signal;

  const update = async () => {
    try {
      setLoading(true);
      await updateFavorite(activeItem, signal);
      select(activeItem);
      setFavorite(activeItem);
      addAlert({
        type: AlertType.SUCCESS,
        body: t('favorites.success.update')
      });
      setLoading(false);
      setDirty(false);
    } catch (error) {
      addAlert({
        type: AlertType.ERROR,
        body: t('favorites.errors.update')
      });
      setLoading(false);
    }
  };

  const create = async () => {
    try {
      setLoading(true);
      await createFavorite(activeItem, signal);
      select(activeItem);
      addAlert({
        type: AlertType.SUCCESS,
        body: t('favorites.success.create')
      });
      setLoading(false);
    } catch (error) {
      addAlert({
        type: AlertType.ERROR,
        body: t('favorites.errors.create')
      });
      setLoading(false);
    }
  };

  // saves the favorite
  const onSaveClick = () => {
    if (activeItem?.title && activeItem.vesselAeCodes?.length && !isLoading) {
      const duplicate = favorites?.find(
        (x: Favorite) =>
          x?.title?.toUpperCase() === activeItem.title?.toUpperCase() &&
          (!activeItem.guid || activeItem.guid !== x.guid)
      );

      if (duplicate) {
        addAlert({
          type: AlertType.ERROR,
          body: t('favorites.errors.duplicate')
        });
        return;
      }

      activeItem.guid ? update() : create();
    }
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return {
    ready: !(!dirty || isLoading || !activeItem.title),
    icon: faFloppyDisk,
    tooltip: activeItem?.guid ? t('action.update') : t('action.save'),
    onSaveClick
  };
};

export default useViewModel;
