import {
  AlertsProvider,
  GlobalStyle,
  SidebarProvider,
  TabsProvider
} from '@angloeastern/react-library';
import AuthProvider from '@context/Auth';
import { Provider as UserProvider } from '@context/AuthUser';
import { Provider as ConfigProvider } from '@context/Config';
import { Provider as FavoriteProvider } from '@context/Favorite';
import { Provider as FeatureTogglesProvider } from '@context/FeatureToggles';
import { Provider as KpiPermissionsProvider } from '@context/KpiPermissions';
import { Provider as MessageBoxProvider } from '@context/MessageBox';
import { Provider as NotificationsProvider } from '@context/Notifications';
import { Provider as OrgProvider } from '@context/Organizations';
import { Provider as PageIDProVider } from '@context/PageID';
import { Provider as ProfileProvider } from '@context/Profile';
import { Provider as ThemeProvider } from '@context/Theme';
import { Provider as VesselProvider } from '@context/Vessels';
import { Provider as ViewProvider } from '@context/View';
import TokenRefresh from '@routes/Oidc/TokenRefresh';
import AppRouter from '../../AppRouter';
import AppWrapper from '../../AppWrapper';
import LanguageHandler from '../../LanguageHandler';

export const CommonProviders: React.FC<{ children: React.ReactNode }> = ({
  children
}) => (
  <ThemeProvider>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

const AuthorizedShell: React.FC<{ children?: any }> = ({ children }) => {
  return (
    <AuthProvider>
      <FeatureTogglesProvider>
        <ConfigProvider>
          <MessageBoxProvider>
            <CommonProviders>
              <ProfileProvider>
                <UserProvider>
                  <KpiPermissionsProvider>
                    <PageIDProVider>
                      <SidebarProvider>
                        <VesselProvider>
                          <OrgProvider>
                            <FavoriteProvider>
                              <ViewProvider>
                                <NotificationsProvider>
                                  <AlertsProvider>
                                    <TabsProvider>
                                      <TokenRefresh />
                                      <LanguageHandler>
                                        <AppWrapper>
                                          <AppRouter>{children}</AppRouter>
                                        </AppWrapper>
                                      </LanguageHandler>
                                    </TabsProvider>
                                  </AlertsProvider>
                                </NotificationsProvider>
                              </ViewProvider>
                            </FavoriteProvider>
                          </OrgProvider>
                        </VesselProvider>
                      </SidebarProvider>
                    </PageIDProVider>
                  </KpiPermissionsProvider>
                </UserProvider>
              </ProfileProvider>
            </CommonProviders>
          </MessageBoxProvider>
        </ConfigProvider>
      </FeatureTogglesProvider>
    </AuthProvider>
  );
};

export default AuthorizedShell;
