import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';

import { useSystemNotifications } from '@components/Layout/SystemNotifications/context';
import type { NotificationMessage } from '@components/Layout/SystemNotifications/context/types';
import { useDateUtils } from '@hooks/useDateUtils';
import useTimeStamp from '@hooks/useTimeStamp';
import { useTranslator } from '@hooks/useTranslator';
import { ICON_MAPPING } from './iconMapping';
import SC from './style';
import useMessageGroup from './useMessageGroup';

export interface Props {
  message: NotificationMessage;
  prevTimestamp: string;
  index: number;
}

const useMessageItem = ({ message, prevTimestamp, index }: Props) => {
  const navigate = useNavigate();
  const { readMessage, setShow } = useSystemNotifications();
  const { t } = useTranslator('notifications', 'label');
  const timestamp = useTimeStamp(message.timestampUtc);
  const { formatDate } = useDateUtils();

  const timeGroup = useMessageGroup(message.timestampUtc);
  const prevGroup = useMessageGroup(prevTimestamp);

  const dateTimestamp =
    timeGroup === t('today') ? timestamp : formatDate(message.timestampUtc);

  const ListItem = message.isRead ? SC.ReadItem : SC.UnreadItem;

  const onClick = () => {
    readMessage(message.notificationGuid);
    navigate(`/${message.query}`);
    setShow(false);
  };

  return {
    message,
    showTimeGroup: index === 0 || timeGroup !== prevGroup,
    timeGroup,
    icon: ICON_MAPPING[message.eventType] || faQuestionCircle,
    dateTimestamp,
    ListItem,
    onClick
  };
};

export default useMessageItem;
