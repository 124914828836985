import { createContext, useContext, useEffect, useState } from 'react';

import { useConfig } from '@context/Config';

export const Context = createContext({});

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { MAPKEY, MAPID = '' } = useConfig();
  const libraries = ['places'] as Array<any>; //, 'marker'
  const [state, setState] = useState({
    googleMapsApiKey: null,
    googleMapsId: '',
    libraries
  });

  useEffect(() => {
    if (MAPKEY) {
      setState({ ...state, googleMapsApiKey: MAPKEY, googleMapsId: MAPID });
    }
  }, [MAPKEY]);

  return <Context.Provider value={{ ...state }}>{children}</Context.Provider>;
};

export const useMapConfig = () => {
  const config = useContext<any>(Context);

  return {
    config
  };
};
