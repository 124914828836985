import { faBell } from '@fortawesome/pro-solid-svg-icons';

import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useEffect } from 'react';
import { useSystemNotifications } from '../context';
import SC from './style';

const AlertIcon: React.FC = () => {
  const { isEnabled } = useFeatureToggles();
  const { show, totalUnread, messages, getMessages, setShow } =
    useSystemNotifications();

  useEffect(() => {
    if (!messages) {
      getMessages();
    }
  }, [messages]);

  return isEnabled(Feature.NOTIFICATIONS) ? (
    <SC.Container
      data-testid="alert-icon"
      onClick={() => {
        setShow(!show);
      }}
    >
      <SC.Icon icon={faBell} />
      {totalUnread !== null && totalUnread > 0 && (
        <SC.Count>{totalUnread > 99 ? `99+` : totalUnread}</SC.Count>
      )}
    </SC.Container>
  ) : null;
};

export default AlertIcon;
