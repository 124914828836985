import { Document } from 'react-pdf';

import useViewModel from './useViewModel';

const MobileView: React.FC = () => {
  const { thePages, pdfData, onDocumentLoadError, onDocumentLoadSuccess } =
    useViewModel();

  return pdfData ? (
    <Document
      file={pdfData}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
    >
      {thePages}
    </Document>
  ) : null;
};

export default MobileView;
