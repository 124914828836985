import { lazy } from 'react';

interface IPage {
  id: string;
  component: any;
}

export const pages: IPage[] = [
  {
    id: 'location',
    component: lazy(() => import('@routes/Location'))
  },
  {
    id: 'worldmap',
    component: lazy(() => import('@routes/WorldMap'))
  },
  {
    id: 'crew',
    component: lazy(() => import('@routes/Crew'))
  },
  {
    id: 'accounts',
    component: lazy(() => import('@routes/Accounts'))
  },
  {
    id: 'opex-supporting',
    component: lazy(() => import('@routes/OpexSupportings'))
  },
  {
    id: 'kpi',
    component: lazy(() => import('@routes/Kpis'))
  },
  {
    id: 'certificates',
    component: lazy(() => import('@routes/Certificates'))
  },
  {
    id: 'reports',
    component: lazy(() => import('@routes/Reports'))
  },
  {
    id: 'documents',
    component: lazy(() => import('@routes/Documents'))
  },
  {
    id: 'drawings',
    component: lazy(() => import('@routes/Drawings'))
  }
];
