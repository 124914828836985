import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import { useSystemNotifications } from '../../context';
import { ViewMode } from '../../context/types';
import ReadAllButton from '../MessageList/ReadAllButton';
import SettingsTitle from './SettingsTitle';
import SC from './style';

const Header: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);
  const { view, setView } = useSystemNotifications();

  return (
    <SC.Container data-testid="display-header">
      <SC.Header>
        {view === ViewMode.SETTINGS && (
          <SC.BackIcon
            data-testid="back-icon"
            icon={faSquareChevronLeft}
            onClick={() => {
              setView(ViewMode.MESSAGES);
            }}
          ></SC.BackIcon>
        )}
        <SC.Title>
          {view === ViewMode.SETTINGS ? (
            <SettingsTitle />
          ) : (
            t('label.notifications')
          )}
        </SC.Title>
        {view === ViewMode.MESSAGES && (
          <>
            <ReadAllButton />
            <SC.Icon
              icon={faGear}
              onClick={() => {
                setView(ViewMode.SETTINGS);
              }}
            />
          </>
        )}
      </SC.Header>
    </SC.Container>
  );
};

export default Header;
