import { useEffect } from 'react';

import { useSidebar } from '@angloeastern/react-library';
import { Features, Layout, Page } from '@components/index';
import { PageID } from '@context/PageID/types';
import Navigation from './Navigation';

const Location: React.FC = () => {
  const {
    state: { activeItem, menu },
    setActiveItem,
    getMenuItem
  } = useSidebar();

  useEffect(() => {
    if (menu && (!activeItem || activeItem.id !== 'location')) {
      const menuItem = getMenuItem('location');
      menuItem && setActiveItem(menuItem);
    }
  }, [activeItem, menu]);

  return (
    <Page pageID={PageID.LOCATION} permit={Features.LOCATION_NAVIGATION}>
      <Layout>
        <Navigation />
      </Layout>
    </Page>
  );
};

export default Location;
