import type { Dispatch } from 'react';

import type { Favorite } from '@context/Favorite/types';
import getCurrencySymbol from '@utils/currency';
import { errorTracker } from '@utils/errorTracker';
import * as Api from './api';
import type { Action, Vessel, Vessels } from './types';
import { Actions, currencySymbols } from './types';

export const fetchVessels =
  (dispatch: Dispatch<Action>) =>
  async (vesselCodes: string[] | null, signal?: AbortSignal) => {
    try {
      const data: Vessels = await Api.fetchVessels(signal);

      let vessels: Vessels = data;

      if (vesselCodes && vesselCodes !== null)
        vessels = vessels.filter((x) => vesselCodes.includes(x.aeCode));

      vessels = vessels
        .sort((a: Vessel, b: Vessel) => {
          const aName = a.name.toLocaleLowerCase().trim();
          const bName = b.name.toLocaleLowerCase().trim();

          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          return 0;
        })
        .sort((a: Vessel, b: Vessel) => {
          if (a.active && !b.active) return -1;
          if (!a.active && b.active) return 1;
          return 0;
        })
        .map((v: Vessel, idx: number) => {
          v.sortKey = `index-${idx}`;
          v.currencySymbol =
            getCurrencySymbol(v.currency) || currencySymbols.DEFAULT;
          v.locale = v?.currency === 'INR' ? 'en-IN' : 'en-US';
          return v;
        });

      dispatch({ type: Actions.FETCH_VESSELS, payload: vessels });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({ type: Actions.SET_ERROR });
      }
    }
  };

export const setVessel = (dispatch: Dispatch<Action>) => (vessel: Vessel) => {
  dispatch({ type: Actions.SET_VESSEL, payload: vessel });
};

export const setFavorite =
  (dispatch: Dispatch<Action>) => (favorite: Favorite) => {
    dispatch({ type: Actions.SET_FAVORITE_FILTER, payload: favorite });
  };

export const clearFavorite = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.REMOVE_FAVORITE_FILTER });
};

export const setVessels =
  (dispatch: Dispatch<Action>) => (vessels: Vessels) => {
    dispatch({ type: Actions.SET_VESSELS, payload: vessels });
  };

export const resetData = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.RESET_DATA });
};
