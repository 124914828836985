import { useConfirmDialog } from '@angloeastern/react-library';
import { useProfile } from '@context/Profile';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { closeForm } = useProfile();
  const { t } = useTranslator(TranslationNS.COMMON, 'myProfile');
  const { setConfirm } = useConfirmDialog();

  const onCancel = () => setConfirm(false);

  const onContinue = () => {
    setConfirm(false);
    closeForm();
  };

  return {
    title: t('cancelConfirmation'),
    noLabel: t('cancel'),
    yesLabel: t('continue'),
    version: 'v2',
    onYes: onContinue,
    onNo: onCancel,
    children: t('changeDiscardAlert')
  };
};

export default useViewModel;
