import { clarity } from 'clarity-js';
import md5 from 'md5';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { useAuthUser } from '@context/AuthUser';
import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';
import { getDefaultLanguage, getSupportedLanguages } from '@utils/index';

const AppRouter: React.FC<{ children?: any }> = ({ children }) => {
  const { pathname } = useLocation();
  const {
    state: { user }
  } = useProfile();
  const navigate = useNavigate();
  const { token, userLanguage, error } = useAuthUser();
  const {
    i18n: { changeLanguage }
  } = useTranslator();

  let lastIdentity = '';
  useEffect(() => {
    if (pathname && user && token) {
      const currIdentity = `${pathname}-${user?.userDetails?.email}-${token}`;
      // avoids repeated calls for the same path
      if (lastIdentity !== currIdentity) {
        lastIdentity = currIdentity;
        clarity.identify(user?.userDetails?.email, md5(token), pathname);
      }
    }
  }, [pathname, user, token]);

  const handleChange = useCallback(
    (lang: string) => {
      const supported = getSupportedLanguages();
      const pathSplit = pathname.split('/');
      pathSplit.shift();
      if (supported.includes(pathSplit[0])) pathSplit.shift();

      navigate(
        `/${lang === 'en' || lang === '' ? '' : lang + '/'}${pathSplit.join(
          '/'
        )}`
      );
      changeLanguage(lang);
    },
    [pathname, changeLanguage, navigate]
  );

  useEffect(() => {
    if (userLanguage) {
      const lang = userLanguage.toLowerCase();
      if (lang && lang !== getDefaultLanguage()) {
        handleChange(lang);
      }
    }
  }, [userLanguage, handleChange]);

  useEffect(() => {
    if (error) {
      let lang = '';
      if (userLanguage) {
        lang = userLanguage.toLowerCase();
        lang = lang === 'en' ? '' : `/${lang}`;
      }
      navigate(`${lang}/login`);
    }
  }, [error, userLanguage]);

  return token ? children : <BlueWorldMapLoader />;
};

export default AppRouter;
