import { useSystemNotifications } from '@components/Layout/SystemNotifications/context';
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import SC from './style';

const ReadAllButton: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);
  const { showReadAll, readAll } = useSystemNotifications();
  return showReadAll ? (
    <SC.Container onClick={() => readAll()}>
      <SC.Icon icon={faCheckDouble} />
      <SC.Label>{t('label.readAll')}</SC.Label>
    </SC.Container>
  ) : null;
};

export default ReadAllButton;
