import { useDateUtils } from '@hooks/useDateUtils';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';

const useMessageGroup = (timestamp: string) => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS, 'label');
  const { formatDate } = useDateUtils();
  const currentTime = new Date();
  const messageTime = new Date(timestamp);
  currentTime.setHours(0, 0, 0, 0);
  messageTime.setHours(0, 0, 0, 0);
  const diffInSeconds = (currentTime.getTime() - messageTime.getTime()) / 1000;
  const diffInDays = diffInSeconds / 86400;

  if (diffInDays < 1) {
    return t('today');
  } else if (diffInDays >= 1 && diffInDays < 2) {
    return t('yesterday');
  } else {
    return formatDate(timestamp);
  }
};

export default useMessageGroup;
