import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = (view?: string) => {
  const { t } = useTranslator();
  const {
    state: { vessel }
  } = useVessels();

  const message = view
    ? t('text.selectVesselToViewPage', { drive: view })
    : vessel
      ? t('text.selectedVessel', { name: vessel.name })
      : t('text.selectVessel');

  return {
    message,
    styles: `min-height: calc(100vh - 96px);`
  };
};

export default useViewModel;
