import Layout from '@components/Layout';
import BluishRipples from '../BluishRipples';
import SC from './style';

const PageLoader: React.FC = () => (
  <Layout>
    <SC.Container>
      <BluishRipples />
    </SC.Container>
  </Layout>
);

export default PageLoader;
