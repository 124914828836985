import BlankView from '@components/BlankView';
import useViewModel from './useViewModel';

const BlankViewOrg: React.FC<{ view?: string }> = ({ view }) => {
  const { message, styles } = useViewModel(view);

  return <BlankView styleOverrides={styles}>{message}</BlankView>;
};

export default BlankViewOrg;
