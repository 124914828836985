import { useState } from 'react';

import { useFavorite } from '@context/Favorite';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const [duplicate, setDuplicate] = useState(false);

  const {
    state: { dirty, activeItem, isLoading }
  } = useFavorite();

  return {
    dirty,
    label: t('favorites.saveAsFavorite'),
    duplicate,
    activeItem,
    isLoading,
    onDuplicate: setDuplicate
  };
};

export default useViewModel;
