import styled from 'styled-components';

const Container = styled.div`
  display: block;
  position: absolute;
  top: 42px;
  right: -16px;
  width: 100vw;
  max-height: calc(100vh - 90px);
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 3.04px 3.04px 6.08px 6.08px #0000001a;
  z-index: 5;

  &:before {
    content: ' ';
    left: auto;
    right: 140px;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
    margin-left: 3px;
    bottom: 100%;
    border-bottom-color: ${({ theme }) => theme.colors.white};
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.5));
  }

  @media only screen and (min-width: 768px) {
    max-width: 375px;
    top: 50px;

    &:before {
      right: 167px;
    }
  }
`;

const SC = {
  Container
};

export default SC;
