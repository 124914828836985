import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ArrowRight = styled(FontAwesomeIcon)`
  display: inline;
  font-size: 16px;
  flex: 0 0;
  color: ${({ theme }) => theme.colors.weldonBlue};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.darkCyan};
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;

  .marker-label {
    ${({ theme }) => theme.text.shadow.licorice}
  }
`;

const SC = {
  Container
};

export default SC;
