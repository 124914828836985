import ButtonsRow from './ButtonsRow';
import CancelConfirmation from './CancelConfirmation';
import EmailField from './EmailField';
import ImageUploader from './ImageUploader';
import LanguageSelector from './LanguageSelector';
import NameRow from './NameRow';
import Organizations from './Organizations';
import { Provider } from './context';
import SC from './style';

const ProfileTab: React.FC = () => {
  return (
    <Provider>
      <ImageUploader />
      <SC.FormContainer>
        <NameRow />
        <SC.FormRow>
          <EmailField />
          <Organizations />
        </SC.FormRow>
        <SC.FormRow>
          <LanguageSelector />
        </SC.FormRow>
        <ButtonsRow />
      </SC.FormContainer>
      <CancelConfirmation />
    </Provider>
  );
};

export default ProfileTab;
