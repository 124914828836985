import styled from 'styled-components';

const SettingTitle = styled.span`
  font-size: 12px;
  &:first-child {
    color: ${({ theme }) => theme.colors.darkCyan};
  }
  &:last-child {
    font-size: 16px;
    font-weight: 700;
  }
`;

const SC = {
  SettingTitle
};

export default SC;
