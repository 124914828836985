import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SC from './style';
import type { Props } from './useViewModel';
import useViewModel from './useViewModel';

const MessageItem: React.FC<Props> = (props) => {
  const {
    message,
    showTimeGroup,
    timeGroup,
    icon,
    dateTimestamp,
    ListItem,
    onClick
  } = useViewModel(props);

  return (
    <>
      {showTimeGroup && <SC.Group>{timeGroup}</SC.Group>}
      <ListItem key={message.notificationGuid} onClick={onClick}>
        <SC.IconWrapper data-testid="icon">
          <FontAwesomeIcon icon={icon} />
        </SC.IconWrapper>
        <SC.MessageDetail data-testid="notification-message">
          <SC.MessageWrapper>
            <SC.Title>{message.title}</SC.Title>
            <SC.MessageTime>{dateTimestamp}</SC.MessageTime>
          </SC.MessageWrapper>
          <SC.Message>{message.message}</SC.Message>
        </SC.MessageDetail>
      </ListItem>
    </>
  );
};

export default MessageItem;
