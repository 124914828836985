import { useContext } from 'react';

import { useConfig } from '@context/Config';
import createDataContext from '@context/createDataContext';
import * as actions from './actions';
import reducer from './reducer';
import type { State } from './types';

const initialState: State = {
  dummy: {
    vesselAeCode: '',
    timestamp: '',
    isOutDate: false
  }
};

export const { Context, Provider } = createDataContext(
  reducer,
  { ...actions },
  initialState
);

export const useVesselLastTimeStamp = () => {
  const ctx = useContext<any>(Context);
  const { OUTDATED_MIN_HOURS } = useConfig();

  const getLatestTimeStampInfo = (aeCode: string) => {
    const { state } = ctx;
    return state[aeCode];
  };

  const isOutdated = (timestamp: Date | string) => {
    const thisTime = new Date().getTime();
    const theTimeStamp = new Date(timestamp).getTime();
    const recordAgeInHours = Math.abs(thisTime - theTimeStamp) / 36e5;
    return recordAgeInHours >= OUTDATED_MIN_HOURS;
  };

  return {
    ...ctx,
    getLatestTimeStampInfo,
    isOutdated
  };
};
