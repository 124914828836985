import { ConfirmDialogProvider } from '@angloeastern/react-library';
import FilterButton from './FilterButton';
import Form from './Form';

const Favorite: React.FC = () => (
  <ConfirmDialogProvider>
    <FilterButton />
    <Form />
  </ConfirmDialogProvider>
);

export default Favorite;
