import styled from 'styled-components';

const Arrow = styled.span`
  font-size: var(--arrow-size);
  --fa-secondary-opacity: 1;
  align-self: auto;
  flex: 0 0;
`;

const GreenArrow = styled(Arrow).attrs({ 'aria-label': 'green-arrow' })`
  --fa-primary-color: ${({ theme }) => theme.colors.robinEggBlue};
  --fa-secondary-color: rgba(0, 176, 152, 0.25);
`;

const YellowArrow = styled(Arrow).attrs({ 'aria-label': 'yellow-arrow' })`
  --fa-primary-color: ${({ theme }) => theme.colors.buttercup};
  --fa-secondary-color: ${({ theme }) => theme.colors.bananaMania};
`;

const SC = {
  GreenArrow,
  YellowArrow
};

export default SC;
