import { BREAKPOINTS } from '@components/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Viewport = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 0;
  background: transparent;
  margin: 14px auto;
  position: relative;
  text-align: center;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    flex: 0 0;
    width: 100vh;
    height: 100vh;
  }
`;

const Slides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const Slide = styled.div.attrs({ 'aria-label': 'slide' })`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 100vh;
    height: fit-content;
  }
`;

const SlideInner = `
  width: 100%;
  object-fit: contain;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

const Image = styled.img.attrs({ 'aria-label': 'slide-image' })`
  ${SlideInner}
`;

const Video = styled.video.attrs({ 'aria-label': 'slide-video' })`
  ${SlideInner}
`;

const SlideTop = styled.div`
  display: flex;
  margin: 4px 22px;
  padding: 12px;
  flex-grow: 1;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
`;

const SlideBottom = styled.div`
  align-self: center;
`;

const Filename = styled.div`
  flex: 1 0;
  text-align: left;
  align-self: center;
  font-size: 16px;
`;

const TopControls = styled.div`
  flex: 0 0;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    flex: 0 0 60px;
  }
`;

const TopControl = styled.div<{ $empty?: boolean }>`
  align-self: center;
  cursor: pointer;
  visibility: ${({ $empty }) => ($empty ? 'hidden' : 'visible')};

  svg {
    font-size: 22px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brightYellow};
  }
`;

const CloseIcon = styled(TopControl)`
  display: none;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    display: revert;
  }
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 18.3px;
  align-self: center;
  margin-right: 22px;

  @media only screen and (min-width: ${BREAKPOINTS.desktop}) {
    display: none;
  }
`;

const Ripples = styled.div`
  height: 75vh;
`;

const SC = {
  SlideTop,
  SlideBottom,
  Filename,
  TopControls,
  TopControl,
  CloseIcon,
  BackIcon,
  Viewport,
  Slides,
  Slide,
  Image,
  Video,
  Ripples
};

export default SC;
