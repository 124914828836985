import { apiGet, apiPut } from '@api/callAPI';
import { CHUNK_SIZE } from './types';

export const fetchMessages = (
  language = 'en',
  pageNumber = 1,
  pageSize = CHUNK_SIZE,
  signal?: AbortSignal
) =>
  apiGet(
    `/notifications/system/${language}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    false,
    { signal }
  );

export const readMessages = (guids: string[], signal?: AbortSignal) =>
  apiPut(`/notifications/read-messages`, guids, true, { signal });
