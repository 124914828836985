import styled from 'styled-components';

const Group = styled.div`
  padding-block: 12px 4px;
  font-weight: 600;
  font-size: 14px;
`;

const UnreadItem = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 20px 16px;
  box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.black10};
  background: ${({ theme }) => theme.colors.columbiaBlue};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.platinum};
  border-radius: 8px;
  box-shadow: 0px -1px 0px 0px #c4c4c426 inset;
  border: 1px solid white;

  &:hover {
    border: 1px solid #00b2ba;
  }
`;

const ReadItem = styled(UnreadItem)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -1px 0px 0px #c4c4c426 inset;
`;

const IconWrapper = styled.div`
  border: 0.5px solid #013861;
  background-color: #013861;
  border-radius: 50%;
  width: 28px;
  min-width: 28px;
  height: 28px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageDetail = styled.div`
  flex-grow: 1;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const Message = styled.div`
  font-size: 12px;
`;

const MessageTime = styled.div`
  font-size: 9px;
  color: ${({ theme }) => theme.colors.darkSilver};
`;

const SC = {
  Group,
  UnreadItem,
  ReadItem,
  IconWrapper,
  MessageDetail,
  MessageWrapper,
  Title,
  Message,
  MessageTime
};

export default SC;
