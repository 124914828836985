import { useSecurityTab } from '../context';
import SC from '../style';
import Buttons from './Buttons';
import ConfirmPassword from './ConfirmPassword';
import CurrentPassword from './CurrentPassword';
import Header from './Header';
import NewPassword from './NewPassword';

export const PasswordForm: React.FC = () => {
  const { isOffice365, formKey } = useSecurityTab();

  return !isOffice365 ? (
    <SC.FormContainer key={formKey}>
      <Header />
      <CurrentPassword />
      <NewPassword />
      <ConfirmPassword />
      <Buttons />
    </SC.FormContainer>
  ) : null;
};

export default PasswordForm;
