import { ConfirmDialog } from '@angloeastern/react-library';
import useViewModel from './useViewModel';

const ConfirmRemove: React.FC = () => {
  const props = useViewModel();

  return <ConfirmDialog {...props} />;
};

export default ConfirmRemove;
