import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';

import { useConfig } from '@context/Config';

const useEncryption = () => {
  const { UI_URL_SECRET } = useConfig();

  const decrypt = (data: string): string =>
    CryptoJS.AES.decrypt(data, UI_URL_SECRET).toString(CryptoJS.enc.Utf8);

  const encrypt = (data: string): string =>
    CryptoJS.AES.encrypt(data, UI_URL_SECRET).toString();

  const encode64 = (data: string) =>
    encodeURIComponent(Buffer.from(data).toString('base64'));

  const decode64 = (data: string) =>
    Buffer.from(decodeURIComponent(data), 'base64').toString();

  const encodeCrypt = (data: string) =>
    encodeURIComponent(encode64(encrypt(data)));

  const decodeCrypt = (data: string) =>
    decrypt(decode64(decodeURIComponent(data)));

  return {
    decrypt,
    encrypt,
    encode64,
    decode64,
    encodeCrypt,
    decodeCrypt
  };
};

export default useEncryption;
