import { errorTracker } from '@utils/errorTracker';
import { useEffect, useMemo, useState } from 'react';
import { useNotifications as useNotif } from '../../../context/Notifications';
import { fetchNotificaitons } from './api';

interface INotification {
  message: string;
}

const messageTime = 5000;

const useViewModel = (autoClose = false) => {
  const [active, setActive] = useState(true);
  const [notifications, setNotifications] = useState<INotification[] | null>(
    null
  );
  const { show, openNotifications, closeNotifications } = useNotif();

  const getNotifications = async (signal?: AbortSignal) => {
    try {
      const response = await fetchNotificaitons(signal);
      setNotifications(response); // Ensure this updates the state
      if (response?.length && show) {
        openNotifications();
      }
    } catch (error: any) {
      if (error.name !== 'AbortError' && error.message !== 'canceled') {
        errorTracker(error);
      }
    }
  };

  const messages = useMemo(() => {
    if (notifications) {
      return notifications.map((m) => m.message);
    }
    return [];
  }, [notifications]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // This should trigger fetching notifications if active and notifications are null
    if (active && !notifications) getNotifications(signal);

    return () => {
      controller.abort();
      setActive(false);
    };
  }, [active, notifications]); // Ensure both dependencies are present

  useEffect(() => {
    if (show && notifications && notifications.length) {
      if (autoClose) {
        const timer = setTimeout(() => {
          closeNotifications();
        }, messageTime);

        return () => clearTimeout(timer); // Cleanup timeout
      }
    }
  }, [show, notifications, autoClose]);

  return {
    show: show && !(!notifications || notifications.length === 0),
    messages,
    closeNotifications
  };
};

export default useViewModel;
