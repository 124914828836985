import styled from 'styled-components';

const Top = styled.div`
  display: flex;
  align-content: center;
  padding: 24px;
  gap: 10px;
  font-size: 13px;
  align-items: center;

  @media only screen and (min-width: 768px) {
    padding: 0 4px 25px 4px;
    font-size: 16px;
  }
`;

const Controls = styled.div`
  flex: 0 0;
  gap: 10px;
  align-items: center;
  display: flex;
`;

const ControlIcon = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  width: 18px;
  align-self: center;

  ${({ disabled }) =>
    disabled
      ? `
      pointer-events: none;  
      color: grey;
      a {
        color: grey;
      }
    `
      : `  
      color: white;
      a {
        color: white;
      }
  `};

  svg {
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    &:hover {
      color: ${({ theme }) => theme.colors.brightYellow};

      a {
        color: ${({ theme }) => theme.colors.brightYellow};
      }
    }
  }
`;

const SC = {
  ControlIcon,
  Top,
  Controls
};

export default SC;
