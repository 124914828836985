import type { Dispatch } from 'react';

import { errorTracker } from '@utils/errorTracker';
import * as Api from './api';
import type { Action, Favorite } from './types';
import { Actions } from './types';

export const fetchFavorites =
  (dispatch: Dispatch<Action>) => async (signal?: AbortSignal) => {
    try {
      const data: Favorite[] = await Api.fetchFavorites(signal);
      dispatch({ type: Actions.FETCH_FAVORITES, payload: data });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({
          type: Actions.SET_ERROR,
          payload: 'An error occured while fetching favorites'
        });
      }
    }
  };

export const createFavorite =
  (dispatch: Dispatch<Action>) =>
  async (favorite: Favorite, signal?: AbortSignal) => {
    try {
      await Api.createFavorite(favorite, signal);
      const data: Favorite[] = await Api.fetchFavorites(signal);
      dispatch({ type: Actions.CREATE_FAVORITE, payload: data });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({
          type: Actions.SET_ERROR,
          payload: 'An error occured while saving favorite'
        });
      }
      throw e;
    }
  };

export const updateFavorite =
  (dispatch: Dispatch<Action>) =>
  async (favorite: Favorite, signal?: AbortSignal) => {
    try {
      await Api.updateFavorite(favorite, signal);
      const list: Favorite[] = await Api.fetchFavorites(signal);
      dispatch({ type: Actions.UPDATE_FAVORITE, payload: list });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({
          type: Actions.SET_ERROR,
          payload: 'An error occured while saving favorite'
        });
        throw e;
      }
    }
  };

export const removeFavorite =
  (dispatch: Dispatch<Action>) =>
  async (favorite: Favorite, signal?: AbortSignal) => {
    try {
      await Api.removeFavorite(favorite, signal);
      dispatch({ type: Actions.REMOVE_FAVORITE, payload: favorite });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({
          type: Actions.SET_ERROR,
          payload: 'An error occured while removing favorite'
        });
        throw e;
      }
    }
  };

export const select = (dispatch: Dispatch<Action>) => (favorite: Favorite) =>
  dispatch({ type: Actions.SET_FAVORITE, payload: favorite });

export const unselect = (dispatch: Dispatch<Action>) => async () => {
  const resetDropdown = document.querySelector(
    '#reset-multi-select-dropdown'
  ) as HTMLElement;
  resetDropdown?.click();
  dispatch({ type: Actions.CLEAR_FAVORITE });
};

export const setActiveItem =
  (dispatch: Dispatch<Action>) => (favorite: Favorite) =>
    dispatch({ type: Actions.SET_ACTIVE_ITEM, payload: favorite });

export const clearActiveItem = (dispatch: Dispatch<Action>) => () =>
  dispatch({ type: Actions.CLEAR_ACTIVE_ITEM });

export const clearSelection = (dispatch: Dispatch<Action>) => () =>
  dispatch({ type: Actions.CLEAR_SELECTION });

export const setIsFormOpen =
  (dispatch: Dispatch<Action>) => async (isFormOpen: boolean) =>
    dispatch({ type: Actions.SET_FORM_OPEN, payload: isFormOpen });

export const setDisabled =
  (dispatch: Dispatch<Action>) =>
  (isDisabled: boolean, disabledToolTip: string) => {
    dispatch({ type: Actions.SET_DISABLED, payload: isDisabled });
    dispatch({ type: Actions.SET_DISABLED_TOOLTIP, payload: disabledToolTip });
  };

export const setDirty =
  (dispatch: Dispatch<Action>) => async (dirty: boolean) =>
    dispatch({ type: Actions.SET_DIRTY, payload: dirty });

export const setLoading =
  (dispatch: Dispatch<Action>) => async (loading: boolean) =>
    dispatch({ type: Actions.SET_LOADING, payload: loading });

export const showToast =
  (dispatch: Dispatch<Action>) =>
  async (message: string, success = true) =>
    dispatch({
      type: Actions.SHOW_TOAST,
      payload: {
        message,
        success
      }
    });

export const hideToast = (dispatch: Dispatch<Action>) => async () =>
  dispatch({ type: Actions.HIDE_TOAST });

const actions = {
  fetchFavorites,
  createFavorite,
  updateFavorite,
  removeFavorite,
  select,
  setDirty,
  showToast,
  hideToast,
  unselect,
  setIsFormOpen,
  setDisabled,
  setActiveItem,
  clearActiveItem
};
export default actions;
