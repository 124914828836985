import styled from 'styled-components';

const Container = styled.div.attrs({ 'aria-label': 'summary' })`
  background: white;
  border-radius: 4px;
  padding: 14px 1px;
  margin-left: 16px;
  margin-bottom: 0;

  @media only screen and (min-width: 768px) {
    margin-left: 0;
    margin-bottom: 14px;
  }
`;

const Inner = styled.div`
  display: flex;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Items = styled.div`
  display: inline-flex;
  gap: 2px;
  width: 100%;
  background: ${({ theme }) => theme.colors.brightGray};
  min-height: 80px;
  align-items: center;

  > div {
    height: 100%;
  }
`;

const SC = {
  Container,
  Inner,
  Items
};

export default SC;
