import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const Label = styled.div`
  flex: 0 0 50%;
  color: ${({ theme }) => theme.colors.licorice};
  font-size: 14px;
`;

const Switches = styled.div`
  display: flex;
  gap: 35px;
  flex: 0 0 50%;
  justify-content: center;
`;

const Switch = styled.div`
  width: 32px;
  text-align: center;
`;

const SC = {
  Container,
  Switches,
  Switch,
  Label
};

export default SC;
