import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 5px;
  padding: 0;
  border-radius: 0 0 8px 8px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.aliceBlue};
  cursor: pointer;
  box-shadow: 0px -2px 2px 0px #0000001a;

  &:hover {
    background: ${({ theme }) => theme.colors.darkMidnightBlue};
    box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.black10};

    > svg {
      color: ${({ theme }) => theme.colors.lightCyan};
    }

    > span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  flex: 0 0;
  color: ${({ theme }) => theme.colors.tiffanyBlue};
  font-size: 16px;
  line-height: 16px;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.darkCyan};
  font-size: 12px;
  font-weight: 500;
  text-wrap: nowrap;
  padding-block: 12px;
`;

const SC = {
  Container,
  Icon,
  Label
};

export default SC;
