import { ConfirmDialogProvider } from '@angloeastern/react-library';
import { Provider } from './ImageResizer/context';
import Presentor from './Presentor';

const MyProfile: React.FC = () => {
  return (
    <ConfirmDialogProvider>
      <Provider>
        <Presentor />
      </Provider>
    </ConfirmDialogProvider>
  );
};

export default MyProfile;
