import styled from 'styled-components';

const ParentFolder = styled.div`
  cursor: pointer;
  padding: 24px 15px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.trout};
  background: white;
  border-bottom: 1px solid rgba(120, 120, 120, 0.5);
  position: sticky;
  top: 0;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.tiffanyBlue};
  }

  &:before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-solid);
    content: '\\f060';
    font-size: 14px;
    margin-right: 5px;
  }
`;

const TabContent = styled.div<{ $gutterSize: number }>`
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  ${({ theme }) => theme.scrollbar}

  @media only screen and (min-width: 768px) {
    background: transparent;
    height: calc(100vh - ${({ $gutterSize }) => $gutterSize}px);
    overflow-y: auto;
  }
`;

const MessageBox = styled.div<{ $gutterSize: number }>`
  height: calc(100vh - 350px);
  width: 100%;
  color: ${({ theme }) => theme.colors.weldonBlue};

  @media only screen and (min-width: 768px) {
    display: flex;
    height: calc(100vh - ${({ $gutterSize }) => $gutterSize}px);
    width: 100%;
    box-sizing: border-box;
    min-height: 580px;
    align-items: center;
  }
`;

const Flash = styled.div.attrs({
  className: 'animate__animated animate__flash'
})``;

const SC = {
  ParentFolder,
  MessageBox,
  TabContent,
  Flash
};

export default SC;
