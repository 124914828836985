import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import SC from './style';

const SettingsTitle: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);

  return (
    <>
      <SC.SettingTitle>{t(`label.notifications`)}</SC.SettingTitle>
      <SC.SettingTitle>{t(`label.subscriptionsSettings`)}</SC.SettingTitle>
    </>
  );
};

export default SettingsTitle;
