import { createElement, useEffect, useMemo, useState } from 'react';
import { Page } from 'react-pdf';

import { useFileViewer } from '@angloeastern/react-library';
import { apiGet } from '@api/callAPI';
import { useViewFile } from '../../context';

const useViewModel = () => {
  const { numPages, onDocumentLoadSuccess } = useViewFile();
  const {
    state: { selected }
  } = useFileViewer();
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  const onDocumentLoadError = (error: any) =>
    console.error('Error while loading document! ' + error.message);

  const thePages = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        createElement(Page, {
          key: `page_${i}`,
          pageNumber: i,
          renderAnnotationLayer: false,
          renderTextLayer: false
        })
      );
    }

    return pages;
  }, [numPages]);

  useEffect(() => {
    const fetchPdf = async () => {
      if (selected && !fetching && !pdfData) {
        try {
          setFetching(true);
          const response = await apiGet(`${selected.url}?mobile=1`, false, {
            responseType: 'arraybuffer'
          });
          setPdfData(response.base64);
        } catch (error) {
          console.error('Error fetching PDF:', error);
        } finally {
          setFetching(false);
        }
      }
    };

    fetchPdf();
  }, [selected, fetching, pdfData]);

  return {
    thePages,
    thePreviewUrl: selected?.url,
    pdfData,
    onDocumentLoadError,
    onDocumentLoadSuccess
  };
};

export default useViewModel;
