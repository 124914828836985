import { AlertMessages } from '@angloeastern/react-library';
import { MessageBox } from '@components/index';
import AdminNotifications from './AdminNotifications';
import MenuBar from './Menubar';
import Topbar from './Topbar';
import SC from './style';
import useViewModel from './useViewModel';

const Layout: React.FC<{ children: any }> = ({ children }) => {
  const hookData = useViewModel();

  if (!hookData) return null;

  const { ref, Main, Inner, isSidebarOpen } = hookData;

  return (
    <SC.Container>
      <MessageBox />
      <SC.Sidebar $show={isSidebarOpen} ref={ref}>
        <MenuBar />
      </SC.Sidebar>
      <SC.MainBox>
        <Topbar />
        <Main>
          <Inner>
            <AdminNotifications />
            {children}
            <AlertMessages autoCloseDelay={5} />
          </Inner>
        </Main>
      </SC.MainBox>
    </SC.Container>
  );
};

export default Layout;
