import type { Dispatch } from 'react';

import { errorTracker } from '@utils/errorTracker';
import type { VesselMarker } from '../VesselMarkers/types';
import { fetchVesselVoyage } from './api';
import type { Action } from './types';
import { Actions } from './types';

export const fetchVoyage =
  (dispatch: Dispatch<Action>) =>
  async (
    vesselAeCode: string,
    fosVesselId: string,
    endDateTime: string,
    active = true,
    signal?: AbortSignal
  ) => {
    try {
      let data: any = {};
      if (active) {
        data = await fetchVesselVoyage(
          vesselAeCode,
          fosVesselId,
          endDateTime,
          signal
        );
      }
      data.active = active;
      dispatch({ type: Actions.SET_ACTIVE_VOYAGE, payload: data });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({ type: Actions.SET_ERROR });
      }
    }
  };

export const unsetVoyage = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.UNSET_VOYAGE });
};

export const setError = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SET_ERROR });
};

export const setNoData = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SET_NO_DATA });
};

export const setCentered =
  (dispatch: Dispatch<Action>) => (centered: boolean) => {
    dispatch({ type: Actions.SET_CENTERED, payload: centered });
  };

export const setAbortController =
  (dispatch: Dispatch<Action>) => (controller: AbortController) => {
    dispatch({ type: Actions.SET_CONTROLLER, payload: controller });
  };

export const appendVoyage =
  (dispatch: Dispatch<Action>) => (marker: VesselMarker) => {
    dispatch({ type: Actions.APPEND_VOYAGE, payload: marker });
  };
