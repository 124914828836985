import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useConfig } from '@context/Config';
import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useTranslator } from '@hooks/useTranslator';
import { errorTracker } from '@utils/errorTracker';
import * as Api from './api';
import {
  CHUNK_SIZE,
  ViewMode,
  type ContextType,
  type NotificationMessage
} from './types';

const Context = createContext({} as ContextType);

export const Provider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { language } = useTranslator();
  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState<NotificationMessage[] | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const [totalUnread, setTotalUnread] = useState<number | null>(null);
  const [view, setView] = useState(ViewMode.MESSAGES);

  const getMessages = async (
    pageNumber = 1,
    pageSize = CHUNK_SIZE,
    signal?: AbortSignal
  ) => {
    try {
      const response = await Api.fetchMessages(
        language,
        pageNumber,
        pageSize,
        signal
      );

      if (!response) return;

      if (pageNumber === 1) {
        setMessages(response.data);
      } else {
        const oldMsgs = messages || [];
        setMessages([...oldMsgs, ...response.data]);
      }
      setTotal(response.total);
      setTotalUnread(response.totalUnread);
    } catch (error: any) {
      if (error.name !== 'AbortError' && error.message !== 'canceled') {
        errorTracker(error);
      }
    }
  };

  const readMessage = async (msgId: string) => {
    if (!messages || totalUnread === null) return;

    const messagesCopy = messages ? [...messages] : [];
    const index = messagesCopy.findIndex(
      (msg) => msg.notificationGuid === msgId
    );
    if (index >= 0 && !messagesCopy[index].isRead) {
      messagesCopy[index].isRead = true;
      setMessages(messagesCopy);
      setTotalUnread(totalUnread - 1);
      try {
        await Api.readMessages([msgId]);
      } catch (error: any) {
        if (error.name !== 'AbortError' && error.message !== 'canceled') {
          errorTracker(error);
        }
      }
    }
  };

  const readAll = async () => {
    if (!messages || totalUnread === null) return;
    const messagesCopy = messages ? [...messages] : [];
    const unRead: string[] = [];
    messagesCopy.forEach((msg) => {
      if (!msg.isRead) {
        unRead.push(msg.notificationGuid);
        msg.isRead = true;
      }
      return msg;
    });
    setMessages(messagesCopy);
    setTotalUnread(totalUnread - unRead.length);
    try {
      await Api.readMessages(unRead);
    } catch (error: any) {
      if (error.name !== 'AbortError' && error.message !== 'canceled') {
        errorTracker(error);
      }
    }
  };

  const viewMore = useCallback(() => {
    const msgLen = messages ? messages.length : 0;
    const newPageNumber = msgLen ? Math.round(msgLen / CHUNK_SIZE + 1) : 1;
    getMessages(newPageNumber);
  }, [messages]);

  const showViewMore = useMemo(() => {
    return !!(
      messages &&
      messages.length > 0 &&
      total !== null &&
      total > messages.length
    );
  }, [total, messages]);

  const showReadAll = useMemo(() => {
    if (messages) {
      let unread = 0;
      for (const element of messages) {
        const msg = element;
        if (!msg.isRead) {
          unread++;
        }
      }
      return unread > 0;
    }
    return false;
  }, [messages]);

  const state = useMemo(
    () => ({
      messages,
      show,
      totalUnread,
      showViewMore,
      showReadAll,
      view,
      setView,
      setShow,
      getMessages,
      readMessage,
      readAll,
      viewMore,
      setTotalUnread
    }),
    [messages, show, totalUnread, showViewMore, showReadAll, view]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useSystemNotifications = () => {
  const { WSS_SERVER } = useConfig();
  const webSocketRef = useRef<WebSocket | null>(null);
  const isConnectedRef = useRef(false);
  const connectingRef = useRef(false);
  const { isEnabled } = useFeatureToggles();
  const ctx = useContext(Context);

  useEffect(() => {
    const connect = () => {
      // prevent multiple connections
      if (
        connectingRef.current ||
        isConnectedRef.current ||
        webSocketRef.current
      ) {
        return;
      }

      try {
        connectingRef.current = true;
        webSocketRef.current = new WebSocket(WSS_SERVER);

        webSocketRef.current.onopen = () => {
          isConnectedRef.current = true;
          connectingRef.current = false;
        };

        webSocketRef.current.onmessage = (event: any) => {
          if (event && event.data === 'PING') {
            ctx.getMessages();
          }
        };

        webSocketRef.current.onclose = () => {
          isConnectedRef.current = false;
          connectingRef.current = false;
          webSocketRef.current = null;
        };

        webSocketRef.current.onerror = () => {
          isConnectedRef.current = false;
          webSocketRef.current = null;
        };
      } catch (error: any) {
        console.error('Error creating WebSocket:', error);
        connectingRef.current = false;
      }
    };

    if (WSS_SERVER && isEnabled(Feature.WEBSOCKET)) {
      connect();
    }

    return () => {
      if (
        webSocketRef.current &&
        webSocketRef.current.readyState !== WebSocket.CLOSED &&
        !connectingRef.current
      ) {
        webSocketRef.current.close();
      }
      webSocketRef.current = null;
      isConnectedRef.current = false;
      connectingRef.current = false;
    };
  }, [WSS_SERVER]);

  return ctx;
};

export default Provider;
