import md5 from 'md5';

import http from '@api/http';

const cache: Record<string, Promise<any>> = {};

const callAPI = (
  cacheKey: string | null,
  func: (signal: AbortSignal, ...args: any[]) => Promise<any>,
  signal?: AbortSignal
): Promise<any> => {
  if (!cacheKey) {
    return func(signal || new AbortController().signal);
  }

  if (!cache[cacheKey]) {
    const controller = new AbortController();
    cache[cacheKey] = func(signal || controller.signal);
  }

  return cache[cacheKey];
};

export const api = async (
  method: string,
  func: (
    url: string,
    payload: any,
    config: { signal: AbortSignal }
  ) => Promise<any>,
  url: string,
  payload: any,
  cacheable = true,
  config?: { signal?: AbortSignal }
): Promise<any> => {
  const cacheKey = cacheable
    ? md5(`${method}-${url}-${payload ? JSON.stringify(payload) : ''}`)
    : null;

  return callAPI(
    cacheKey,
    async (signal) => {
      try {
        const { data } = await func(url, payload, { signal });
        return data;
      } catch (error: any) {
        cacheKey && delete cache[cacheKey];
        if (error.name === 'AbortError' || error.message === 'canceled') {
          // If the error is due to abortion, do not retry
          throw error;
        } else {
          console.error(`API call failed :`, url, error);
          throw error;
        }
      }
    },
    config?.signal
  );
};

interface CallConfig {
  signal?: AbortSignal;
  responseType?: string;
}

export const apiGet = (
  url: string,
  cacheable = true,
  config?: CallConfig
): Promise<any> => api('GET', http.get, url, undefined, cacheable, config);

export const apiPost = (
  url: string,
  payload?: any,
  cacheable = true,
  config?: CallConfig
): Promise<any> => api('POST', http.post, url, payload, cacheable, config);

export const apiPut = (
  url: string,
  payload?: any,
  cacheable = true,
  config?: CallConfig
): Promise<any> => api('PUT', http.put, url, payload, cacheable, config);

export const apiDelete = (
  url: string,
  payload?: any,
  cacheable = true,
  config?: CallConfig
): Promise<any> => api('DELETE', http.delete, url, payload, cacheable, config);

export const urlCheck = async (url: string): Promise<any> => {
  try {
    return await http.head(url);
  } catch {
    return null;
  }
};

export default callAPI;
