import { useSystemNotifications } from '@components/Layout/SystemNotifications/context';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import SC from './style';

const ViewMoreButtom: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS);
  const { showViewMore, viewMore } = useSystemNotifications();
  return showViewMore ? (
    <SC.Container onClick={() => viewMore()}>
      <SC.Label>{t('label.viewMore')}</SC.Label>
    </SC.Container>
  ) : null;
};

export default ViewMoreButtom;
