import { Loaders } from '@angloeastern/react-library';
import { useTheme } from 'styled-components';

const BluishRipples: React.FC<{ size?: number }> = ({ size }) => {
  const theme = useTheme();

  return <Loaders.Ripples size={size} color={theme.colors.columbiaBlue} />;
};

export default BluishRipples;
