import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  faComment,
  faDollarSign,
  faFileCertificate,
  // faFileChartPie
  faFileLines,
  faMagnifyingGlassChart,
  faShip,
  faUsers
} from '@fortawesome/pro-light-svg-icons';

export const ICON_MAPPING: Record<string, IconDefinition> = {
  VESSEL_VOYAGE: faShip,
  VESSEL_PARTICULAR: faShip,
  CREW_LAST_CHANGE: faUsers,
  OPEX_READY: faDollarSign,
  OPEX_COMMENT: faComment,
  CERTIFICATE: faFileCertificate,
  INSPECTION: faMagnifyingGlassChart,
  SHAREPOINT_FILE: faFileLines
};
