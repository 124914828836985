import { camelize } from '@angloeastern/react-library';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import SC from '../style';
import type { NullableKPI } from '../types';
import {
  Color,
  Direction,
  Indicator,
  IndicatorGauge,
  upIndicators
} from '../types';

const useViewModel = (kpi: NullableKPI) => {
  const { t } = useTranslator(TranslationNS.KPIS);

  if (!kpi || (!kpi.value && !kpi.subValue && !kpi.footerValue)) {
    return null;
  }

  const {
    subValue,
    subValueUnit,
    subValueLabel,
    subValueLabelUnit,
    indicator,
    subValueExtentionValue
  } = kpi;

  const getDirection = () => {
    if (subValueLabelUnit !== IndicatorGauge.EQUAL) {
      return upIndicators.indexOf(
        subValueLabelUnit?.toLowerCase?.() as IndicatorGauge
      ) >= 0
        ? Direction.UP
        : Direction.DOWN;
    }
    return Direction.FLAT;
  };

  const getColor = (direction: Direction) => {
    if (direction !== Direction.FLAT) {
      const isDirectionUp = direction === Direction.UP;
      const isDirectionDown = direction === Direction.DOWN;
      const greenUp = indicator === Indicator.AIM_HIGH && isDirectionUp;
      const greenDown = indicator === Indicator.AIM_LOW && isDirectionDown;
      return greenUp || greenDown ? Color.GREEN : Color.YELLOW;
    }
    return Color.BLACK;
  };

  const subValueKey = `kpis:kpi.${camelize(
    `${subValueLabelUnit} ${subValueLabel}`
  )}`;

  const direction = getDirection();
  const color = getColor(direction);

  const TextWrapper =
    direction === Direction.FLAT
      ? SC.BlackText
      : color === Color.GREEN
        ? SC.GreenText
        : SC.YellowText;
  const figure =
    direction === Direction.FLAT
      ? t('kpi.noChange')
      : `${Math.abs(subValue as unknown as number).toLocaleString('en')}${subValueUnit}`;

  return {
    direction,
    color,
    subValueKey,
    figure,
    extension: subValueExtentionValue ? ` (${subValueExtentionValue})` : '',
    TextWrapper
  };
};

export default useViewModel;
