import { Loaders, MultiSelectDropdown } from '@angloeastern/react-library';
import SC from '../style';
import useViewModel from './useViewModel';

const SelectVessels: React.FC = () => {
  const {
    label,
    prompt,
    multiSelected,
    options,
    onChange,
    onClear,
    isLoading
  } = useViewModel();

  return isLoading ? (
    <Loaders.Ellipsis />
  ) : (
    <>
      <SC.Label>{label}</SC.Label>
      <MultiSelectDropdown
        idColumn="aeCode"
        textColumn="name"
        prompt={prompt}
        maxSelectedVisible={1}
        selected={multiSelected}
        onChange={onChange}
        onClear={onClear}
        options={options}
        sortColumn=""
      />
    </>
  );
};

export default SelectVessels;
