import type { ReactNode } from 'react';

import SC from './style';

const BlankView: React.FC<{ styleOverrides?: string; children: ReactNode }> = ({
  styleOverrides,
  children
}) => {
  return (
    <SC.Container $overrides={styleOverrides}>
      <SC.Message data-testid="message">{children}</SC.Message>
    </SC.Container>
  );
};

export default BlankView;
