import { useFeatureToggles } from '@context/FeatureToggles';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useSystemNotifications } from '../../context';
import { ViewMode } from '../../context/types';
import { useSystemNotificationsSettings } from './context';

const useViewModel = () => {
  const { data, loading, onSettingChange, fetchNotificationSettings } =
    useSystemNotificationsSettings();
  const { view } = useSystemNotifications();
  const theme = useTheme();
  const { isEnabled } = useFeatureToggles();

  const show = view === ViewMode.SETTINGS;

  useEffect(() => {
    if (!data && show) fetchNotificationSettings();
  }, []);

  /* event_types: CERTIFICATE, CREW_LAST_CHANGE, INSPECTION, OPEX_COMMENT, OPEX_READY, SHAREPOINT_FILE, VESSEL_VOYAGE */
  const filteredData = data?.filter((item) =>
    isEnabled(`NOTIFICATION_SETTING_${item.eventType}`)
  );

  return {
    show,
    loading,
    data: filteredData,
    view,
    loaderProps: {
      size: 48,
      color: theme.colors.robinEggBlue
    },
    onSettingChange
  };
};

export default useViewModel;
