import { FileViewProvider } from '@angloeastern/react-library';
import { Provider } from '@components/SharePointDocList/Gallery/context';
import type { Props } from './Presenter';
import Presenter from './Presenter';

const Gallery: React.FC<Props> = (props) => (
  <Provider>
    <FileViewProvider>
      <Presenter {...props} />
    </FileViewProvider>
  </Provider>
);

export default Gallery;
