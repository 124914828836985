import { useCallback, useContext } from 'react';

import { useVessels } from '@context/Vessels';
import createDataContext from '@context/createDataContext';
import * as actions from './actions';
import reducer from './reducer';
import type { State, VesselMarker } from './types';

const initialState: State = {
  markers: null,
  error: false
};

export const { Context, Provider } = createDataContext(
  reducer,
  { ...actions },
  initialState
);

export const useVesselMarkers = () => {
  const ctx = useContext<any>(Context);
  const {
    state: { vessels },
    vesselAeCodes
  } = useVessels();
  const {
    state: { markers, error }
  } = ctx;

  const fetchMarkers = useCallback(
    async (signal?: AbortSignal) => {
      if (vessels && !error) {
        await ctx.fetchMarkers(vesselAeCodes, signal);
      } else {
        if (error) {
          console.warn('Failed to fetch vessel locations');
        }
      }
    },
    [vessels, error, vesselAeCodes]
  );

  const pollPositions = useCallback(
    async (signal?: AbortSignal) => {
      if (vessels) {
        await ctx.fetchMarkers(vesselAeCodes, signal);
      }
    },
    [vessels, vesselAeCodes]
  );

  const updateMarkers = (newMarkers: VesselMarker[]) =>
    ctx.updateMarkers(newMarkers);

  const getSelectedMarker = (aeCode: string) => {
    let theIndex;
    const theMarker = markers.find((m: VesselMarker, idx: number) => {
      theIndex = idx;
      return m.vesselAeCode === aeCode;
    });
    return theMarker
      ? {
          marker: theMarker,
          index: theIndex
        }
      : null;
  };

  const getUpdatedPosition = useCallback(
    (marker: VesselMarker) => {
      const theMarker = markers.find((m: VesselMarker) => {
        return m.vesselAeCode === marker.vesselAeCode;
      });
      if (theMarker && marker.timestamp) {
        const d1 = new Date(theMarker.timestamp);
        const d2 = new Date(marker.timestamp);

        if (d1 > d2) {
          return theMarker;
        }
      }
      return marker;
    },
    [markers]
  );

  return {
    ...ctx,
    fetchMarkers,
    pollPositions,
    updateMarkers,
    getSelectedMarker,
    getUpdatedPosition
  };
};
