import { faBell, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { TranslationNS, useTranslator } from '@hooks/useTranslator';
import SC from './style';

const Header: React.FC = () => {
  const { t } = useTranslator(TranslationNS.NOTIFICATIONS, 'label');

  return (
    <SC.Container>
      <SC.Label>{t('category')}</SC.Label>
      <SC.Icons>
        <SC.IconBox>
          <SC.Icon icon={faBell} data-testid="icon-bell" />
        </SC.IconBox>
        <SC.IconBox>
          <SC.Icon icon={faEnvelope} data-testid="icon-envelope" />
        </SC.IconBox>
      </SC.Icons>
    </SC.Container>
  );
};

export default Header;
