import { Loaders } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import BluishRipples from '../BluishRipples';
import SC from './style';

const ListLoader: React.FC<{
  bgColor?: string;
  rows?: number;
  cols?: number;
}> = ({ bgColor = 'white', rows = 4, cols = 5 }) => {
  const {
    deviceCheck: { isAboveMobile }
  } = useConfig();

  return isAboveMobile ? (
    <Loaders.Table bgColor={bgColor} rows={rows} cols={cols} />
  ) : (
    <SC.Container>
      <SC.Item>
        <BluishRipples />
      </SC.Item>
      <SC.Item>
        <BluishRipples />
      </SC.Item>
      <SC.Item>
        <BluishRipples />
      </SC.Item>
    </SC.Container>
  );
};

export default ListLoader;
