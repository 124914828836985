import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  width: 34px;
  align-self: center;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.tiffanyBlue};

    svg {
      color: ${({ theme }) => theme.colors.tiffanyBlue};
    }

    div {
      background: ${({ theme }) => theme.colors.sunsetOrange};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  height: 24px;
  font-weight: 400;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 20px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Count = styled.div`
  font-size: 12px;
  position: absolute;
  border-radius: 22px;
  max-width: 24px;
  min-width: 16px;
  height: 14px;
  margin-top: -30px;
  margin-left: 10px;
  background: red;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  padding: 1px 4px;
`;

const SC = {
  Container,
  Icon,
  Count
};

export default SC;
