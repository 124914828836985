import BluishRipples from '@components/Loading/BluishRipples';
import { useMemo } from 'react';
import { useSystemNotifications } from '../../context';
import MessageItem from './MessageItem';
import SC from './style';
import ViewMoreButtom from './ViewMoreButtom';

const MessageList: React.FC = () => {
  const { messages } = useSystemNotifications();

  const messageItems = useMemo(() => {
    if (!messages || messages.length === 0) return null;

    return messages.map((msg, index) => (
      <MessageItem
        key={msg.notificationGuid}
        message={msg}
        prevTimestamp={messages[index > 0 ? index - 1 : 0].timestampUtc}
        index={index}
      />
    ));
  }, [messages]);

  return messages ? (
    <>
      <SC.Container data-testid="message-list">{messageItems}</SC.Container>
      <SC.Footer>
        <ViewMoreButtom />
      </SC.Footer>
    </>
  ) : (
    <SC.Container>
      <BluishRipples />
    </SC.Container>
  );
};

export default MessageList;
