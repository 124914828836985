import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 16px;
  background: ${({ theme }) => theme.colors.white};
  height: 385px;
  overflow-y: auto;
`;

const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  height: 40px;
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f1f1f17a;
`;

const SC = {
  Container,
  Footer,
  Loader
};

export default SC;
