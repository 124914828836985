import worldMap from '@assets/world-map-03.png';
import styled, { keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const LoaderOverlay = styled.div<{
  $top?: number;
  $left?: number;
  $widthOffset?: number;
}>`
  position: fixed;
  top: ${({ $top }) => ($top ? `${$top}px` : '0')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0')};
  width: calc(100% - ${({ $widthOffset }) => `${$widthOffset}px`});
  height: 100%;
  background: linear-gradient(
    120deg,
    #11456c,
    #165d88,
    #1c76a4,
    #228fbc,
    #2aa9d4,
    #31c3ec,
    #228fbc,
    #1c76a4,
    #165d88,
    #11456c,
    #0e3b5f,
    #0b324e,
    #08293d
  );
  background-size: 300% 300%; /* Larger size for the gradient animation */
  animation: ${gradientAnimation} 4s ease infinite; /* Animation duration */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MapOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${worldMap});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8; /* Adjust the opacity as needed */
`;

export default {
  LoaderOverlay,
  MapOverlay
};
