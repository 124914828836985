import { useOrganizations } from '@context/Organizations';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = (view?: string) => {
  const { t } = useTranslator();
  const {
    state: { organization }
  } = useOrganizations();

  const message = view
    ? t('text.selectOrgToViewPage', { drive: view })
    : organization
      ? t('text.selectedOrganization', { name: organization.name })
      : t('text.selectOrganization');

  return {
    message,
    styles: `
      height: calc(100vh - 134px);

      @media only screen and (min-width: 768px) {
        height: calc(100vh - 217px);
      }    
    `
  };
};

export default useViewModel;
