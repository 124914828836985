import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';

import MessageRotator from './MessageRotator';
import SC from './style';
import useViewModel from './useViewModel';

const AdminNotifications: React.FC = () => {
  const { show, messages, closeNotifications } = useViewModel();

  return show ? (
    <SC.Container data-testid="container">
      <SC.Message data-testid="message">
        <MessageRotator messages={messages} />
      </SC.Message>
      <SC.IconBox data-testid="icon-box">
        <SC.CrossIcon
          data-testid="close-icon"
          icon={faCircleXmark}
          onClick={closeNotifications}
        />
      </SC.IconBox>
    </SC.Container>
  ) : null;
};

export default AdminNotifications;
