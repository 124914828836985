import styled from 'styled-components';

const Tab = styled.li<{ $isActive: boolean }>`
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  cursor: pointer;
  width: 194px;
  max-width: 194px;

  ${({ $isActive, theme }) =>
    $isActive
      ? `
    --label-color: ${theme.colors.tiffanyBlue};
    --bar-bg: ${theme.colors.tiffanyBlue};
    border-bottom: 3px solid ${theme.colors.tiffanyBlue};
    `
      : `
    --label-color: ${theme.colors.weldonBlue};
    --bar-bg: transparent;
  `}

  &:last-child {
    padding-right: 2px;
  }
`;

const TabLabel = styled.div`
  padding: 14px 0;
  color: var(--label-color);
  font-size: 14px;
  line-height: 10px;
  margin: 0 auto;
`;

const Bar = styled.div`
  height: 3px;
  width: 54px;
  margin: 0 auto;
  background: var(--bar-bg);
  color: black;
`;

const SC = {
  Tab,
  TabLabel,
  Bar
};

export default SC;
