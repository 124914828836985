import { createContext, useContext, useState } from 'react';

import { useProfile } from '@context/Profile';

const { REACT_APP_DEFAULT_LANGUAGE: DEFAULT_LANGUAGE } = process.env;

const Context = createContext({});

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const {
    state: {
      user: { profile: profileData }
    }
  } = useProfile();
  const [dirty, setDirty] = useState(false);
  const [locale, setLocale] = useState(
    profileData ? profileData.language : DEFAULT_LANGUAGE
  );

  return (
    <Context.Provider
      value={{
        dirty,
        locale,
        profile: profileData,
        setDirty,
        setLocale
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useProfileTab = () => {
  return useContext<any>(Context);
};
