export interface ISetting {
  label: string;
  eventType: string;
  byUI: boolean;
  byEmail: boolean;
}

export type ISettings = ISetting[];

export const labelMappings: ISettings = [
  {
    label: 'certificates',
    eventType: 'CERTIFICATE',
    byEmail: false,
    byUI: false
  },
  {
    label: 'crewChanges',
    eventType: 'CREW_LAST_CHANGE',
    byEmail: false,
    byUI: false
  },
  {
    label: 'inspections',
    eventType: 'INSPECTION',
    byEmail: false,
    byUI: false
  },
  {
    label: 'opexComments',
    eventType: 'OPEX_COMMENT',
    byEmail: false,
    byUI: false
  },
  {
    label: 'accountsPublished',
    eventType: 'OPEX_READY',
    byEmail: false,
    byUI: false
  },
  {
    label: 'documents',
    eventType: 'SHAREPOINT_FILE',
    byEmail: false,
    byUI: false
  },
  {
    label: 'newVoyage',
    eventType: 'VESSEL_VOYAGE',
    byEmail: false,
    byUI: false
  }
];

export type NotificationSettingCategory = 'byEmail' | 'byUI';

export type ContextType = {
  data: ISettings | null;
  loading: boolean;
  fetchNotificationSettings(): void;
  updateNotificationSettings(settings: ISettings): void;
  onSettingChange(
    category: NotificationSettingCategory,
    value: boolean,
    eventType: string
  ): void;
};
