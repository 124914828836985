import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.platinum};

  @media only screen and (min-width: 768px) {
    height: 100%;
  }
`;

const Sidebar = styled.div<{ $show?: boolean }>`
  display: flex;
  position: fixed;
  flex: 0 0;
  width: 300px;
  z-index: 11;
  left: -100%;
  transition-property: left;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0;

  ${({ $show }) =>
    $show &&
    `
    left: 0;
  `}

  @media only screen and (min-width: 768px) {
    position: fixed;
    width: 56px;
    height: 100vh;
    left: 0;
    flex-basis: 56px;
    z-index: 10;
  }
`;

const MainBox = styled.div`
  flex: 1 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    margin-left: 56px;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    ${({ theme }) => theme.scrollbar}
  }
`;

const Main = styled.div`
  padding: 0;
  padding-bottom: 25px;
  position: relative;
  min-height: calc(100vh - 102px);
  max-height: calc(100vh - 25px);
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    max-height: calc(100vh - 25px);
  }
`;

const MainInner = styled.div`
  padding-top: 134px;

  @media only screen and (min-width: 768px) {
    padding: 15px 25px 25px;
    margin-top: 56px;
    min-height: 85%;
    height: calc(100vh - 91px);
  }
`;

const MainInnerMap = styled.div`
  padding-top: 62px;

  @media only screen and (min-width: 768px) {
    padding: 0;
    height: calc(100vh - 56px);
    min-height: 85%;
    margin-top: 56px;
    overflow: hidden;
  }
`;

const MainMap = styled.div`
  padding: 0;
  padding-bottom: 40px;
  position: relative;
  margin-top: 134px;
  min-height: calc(100vh - 134px);

  @media only screen and (min-width: 768px) {
    padding: 0;
    min-height: 85%;
    margin-top: 56px;
  }
`;

const SC = {
  Container,
  Sidebar,
  MainBox,
  Main,
  MainInner,
  MainInnerMap,
  MainMap
};

export default SC;
