import type { Dispatch } from 'react';

import { arrayChunk } from '@utils/arrayChunk';
import { errorTracker } from '@utils/errorTracker';
import { fetchFleetMarkers } from './api';
import type { Action, FleetMarkers } from './types';
import { Actions } from './types';

const CHUNK_SIZE = 800;

export const fetchMarkers =
  (dispatch: Dispatch<Action>) =>
  async (vesselAeCodes: Array<string>, signal?: AbortSignal) => {
    try {
      const chunkedCodes = arrayChunk(vesselAeCodes, CHUNK_SIZE);
      let allData: any[] = [];
      for (let i = 0; i < chunkedCodes.length; i++) {
        const chunk = chunkedCodes[i];
        const data = await fetchFleetMarkers(chunk, signal);
        if (data && data.length) {
          allData = [...allData, ...data];
        }
      }
      dispatch({ type: Actions.FETCH_MARKERS, payload: allData });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({ type: Actions.SET_ERROR });
      }
    }
  };

export const updateMarkers =
  (dispatch: Dispatch<Action>) => (markers: FleetMarkers) => {
    dispatch({ type: Actions.UPDATE_MARKERS, payload: markers });
  };

export const unsetMarkers = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.UNSET_MARKERS });
};
