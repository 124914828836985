import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MenuItem as MenuItemType } from '@angloeastern/react-library';
import { useSidebar } from '@angloeastern/react-library';
import { useAuthUser } from '@context/AuthUser';
import { PageID, usePageID } from '@context/PageID';
import useFreshDesk from '@hooks/useFreshDesk';
import { useTranslator } from '@hooks/useTranslator';
import { getCurrentLanguage } from '@utils/index';
import { MenuConfig, PermissionMap } from './config';

const HOME_PATH = '/';

const useViewModel = () => {
  const navigate = useNavigate();
  const { t, language } = useTranslator();
  const { permissions, hasPermission } = useAuthUser();
  const {
    state: { menu },
    setMenu
  } = useSidebar();
  const { isPage } = usePageID();
  const { showForm } = useFreshDesk();

  const getMenu = () => {
    if (isPage(PageID.OPEXSUPPORTING)) return [];

    const itemsCopy = Object.assign([], MenuConfig);
    return itemsCopy
      .map((item: MenuItemType) => {
        const itemCopy = Object.assign({}, item);
        itemCopy.name =
          item.name.substring(0, 5) === 'menu.' ? t(item.name) : item.name;
        return itemCopy;
      })
      .filter((item: MenuItemType) => {
        if (PermissionMap[item.id] !== undefined) {
          const permitted = hasPermission(PermissionMap[item.id]);
          return item.id === 'kpi' ? permitted : permitted;
        }
        return true;
      });
  };

  const handleChangePage = (page: string) => {
    const lang = getCurrentLanguage(language);
    page = page === HOME_PATH ? '/' : page;
    const localePage =
      lang === 'en' ? page : `/${lang}${page === '/' ? '' : page}`;
    navigate(localePage);
  };

  const handleClick = (id: string) => {
    if (id === 'help') {
      showForm();
    } else if (id == 'resources') {
      window.open(
        'https://www.angloeastern.com/content-hub',
        '_blank',
        'noopener'
      );
    }
  };

  useEffect(() => {
    setMenu(getMenu());
  }, [permissions, language]);

  return {
    menu,
    handleClick,
    handleChangePage
  };
};

export default useViewModel;
