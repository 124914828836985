import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c4c4c44d;
  padding: 16px 16px;

  div {
    display: flex;
    gap: 35px;
  }

  p {
    flex: 0 60%;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.licorice};
  }
`;

const Label = styled.div`
  flex: 0 0 50%;
  color: ${({ theme }) => theme.colors.licorice};
  font-size: 14px;
  font-weight: bold;
`;

const IconBox = styled.div`
  width: 32px;
  justify-content: center;
`;

const Icons = styled.div`
  flex: 0 0 50%;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.licorice};
`;

const SC = {
  Container,
  IconBox,
  Label,
  Icons,
  Icon
};

export default SC;
