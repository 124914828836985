import { useCallback, useContext } from 'react';

import createDataContext from '../createDataContext';
import {
  closeForm,
  closeMenu,
  edit,
  fetchData,
  openForm,
  openMenu,
  update
} from './actions';
import reducer from './reducer';
import type { State } from './types';

const initialState: State = {
  user: null,
  showMenu: false,
  showForm: false,
  error: false,
  unauthorized: false
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    fetchData,
    update,
    edit,
    openForm,
    openMenu,
    closeForm,
    closeMenu
  },
  initialState
);

export const useProfile = () => {
  const ctx = useContext<any>(Context);

  const {
    state: { user }
  } = ctx;

  const getInitial = useCallback(() => {
    const { firstName, lastName } = user
      ? user.userDetails
      : {
          firstName: '?',
          lastName: '?'
        };
    const first = firstName ? firstName[0] : '?';
    const last = lastName ? lastName[0] : '?';

    return `${first}${last}`;
  }, [user]);

  return {
    ...ctx,
    getInitial
  };
};
