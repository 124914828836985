import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lotion};
  box-shadow: 0px 7px 20px ${({ theme }) => theme.colors.black07};
  position: fixed;
  height: 134px;
  width: 100%;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
    height: 56px;
    width: calc(100vw - 56px);
    left: 56px;
    z-index: 10;
  }
`;

const TopSide = styled.div`
  display: flex;
  flex: 1 0;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.lotion};
  justify-content: space-between;
  max-height: 30px;

  @media only screen and (min-width: 768px) {
    padding: 0 22px;
    max-height: unset;
  }
`;

const Bars = styled.div`
  flex: 0 0;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  flex: 1 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
  align-self: center;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const TopRightSide = styled.div`
  display: flex;
  gap: 10px;
  flex: 0 0;
  width: 160px;
  max-width: 160px;
  align-self: center;
  align-items: center;
  height: 100%;
  position: relative;

  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const LangBox = styled.div`
  flex: 1 0;
  width: 60px;
  height: 100%;
  max-width: 60px;
  align-self: center;

  @media only screen and (min-width: 768px) {
    width: 80px;
    max-width: 80px;
  }
`;

const BottomSide = styled.div`
  flex: 1 0;
  background-color: ${({ theme }) => theme.colors.darkMidnightBlue};
  color: white;
  padding: 0;

  ul {
    z-index: 50;
  }

  @media only screen and (min-width: 768px) {
    background-color: ${({ theme }) => theme.colors.lotion};
    color: ${({ theme }) => theme.colors.licorice};

    ul {
      width: 302px;
    }
  }
`;

const Hamburger = styled(FontAwesomeIcon)`
  width: 24px !important;
  height: 24px;
  font-weight: 400;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const BetaBox = styled.div`
  padding: 0 24px;
  align-self: center;
  flex: 0 0;
  margin-left: 24px;

  @media only screen and (min-width: 768px) {
    margin-left: 0;
    vertical-align: middle;
  }
`;

const WhatsNewBox = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    align-self: center;
    flex: 0 0;
    cursor: pointer;
    margin-left: 8px;
    vertical-align: middle;
  }
`;

const ControlArea = styled.div`
  display: flex;
  height: 100%;
`;

const AEText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  align-self: center;
  padding: 12px 0 12px 20px;
  background: ${({ theme }) => theme.colors.darkMidnightBlue};
  width: 282px;

  @media only screen and (min-width: 768px) {
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.gray98} 0%,
      ${({ theme }) => theme.colors.brightGray} 100%
    );
  }
`;

const SC = {
  Container,
  AEText,
  TopSide,
  BottomSide,
  Hamburger,
  Bars,
  Title,
  ControlArea,
  LangBox,
  TopRightSide,
  BetaBox,
  WhatsNewBox
};

export default SC;
