import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_LOOKUP:
      return action.payload;
    case Actions.UPDATE_LOOKUP:
      return {
        ...state,
        [action.payload.vesselAeCode]: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
