import styled from 'styled-components';

const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 8px 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 12px 12px;
  border-radius: 0 0 8px 8px;
  background: ${({ theme }) => theme.colors.white};
  height: 385px;
  overflow-y: auto;
  gap: 8px;
`;

const SC = {
  Container,
  Footer
};

export default SC;
