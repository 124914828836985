import type { Favorite } from '@context/Favorite/types';
import type { Action, State, Vessel } from './types';
import { Actions } from './types';

const vesselReducer = (state: State, action: Action): State => {
  const setFavorite = (payload: Favorite) => {
    const vesselAeCodes =
      payload && payload.vesselAeCodes ? payload.vesselAeCodes : [];
    const vessels = state.allVessels
      ? state.allVessels.filter(
          (v: Vessel) => vesselAeCodes.indexOf(v.aeCode) >= 0
        )
      : [];

    const selectedVesselInfilter = state.vessel
      ? vessels.some((v: Vessel) => v.aeCode === state.vessel?.aeCode)
      : false;

    return {
      ...state,
      favorite: payload,
      vessels,
      vessel: state.vessel && selectedVesselInfilter ? state.vessel : null
    };
  };

  switch (action.type) {
    case Actions.SET_FAVORITE_FILTER:
      return setFavorite(action.payload);
    case Actions.REMOVE_FAVORITE_FILTER:
      return {
        ...state,
        favorite: null,
        vessels: state.allVessels,
        vessel: null
      };
    case Actions.FETCH_VESSELS:
      return {
        ...state,
        allVessels: [...action.payload],
        error: false
      };
    case Actions.SET_VESSELS:
      return {
        ...state,
        vessels: action.payload,
        error: false
      };
    case Actions.SET_VESSEL:
      return {
        ...state,
        vessel: action.payload
      };
    case Actions.RESET_DATA:
      return {
        ...state,
        allVessels: null,
        vessels: null,
        error: false
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};

export default vesselReducer;
